import { styled } from '@mui/material';

export const CardWrapper = styled('div')(({ theme }) => ({
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  height: 'auto',
  gap: theme.spacings.unit(2),
  padding: `${theme.spacings.unit(4)} ${theme.spacings.unit(
    6
  )} ${theme.spacings.unit(6)} ${theme.spacings.unit(6)}`,
  '&:hover': {
    cursor: 'pointer',
  },
}));

export const ImageWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  aspectRatio: '1/1',
  transition: 'transform 0.3s ease-out',
  '&:hover': {
    transform: 'scale3d(1.10, 1.10, 1.10)',
  },
}));

export const InfoWrapper = styled('div')(({ theme }) => ({
  zIndex: '1',
  display: 'flex',
  flexDirection: 'column',
  gap: `${theme.spacings.unit(1)}`,
}));

export const CategoryWrapper = styled('div')(({ theme }) => ({
  wordBreak: 'break-word',
  color: theme.colors.primary,
  fontSize: theme.fonts.utility.size(6),
  fontWeight: '900',
  lineHeight: '120%',
  textTransform: 'uppercase',
}));

export const NameWrapper = styled('a')(({ theme }) => ({
  wordBreak: 'break-word',
  color: `${theme.colors.primaryText}`,
  fontSize: theme.fonts.utility.size(10),
  fontWeight: '400',
  lineHeight: '140%',
  textDecoration: 'none',
}));
