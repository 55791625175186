import { styled } from '@mui/material';

export const Presentation = styled('div')(({ theme, elementsNr }) => ({
  padding: `${theme.spacings.unit(12)} ${theme.spacings.unit(
    24
  )} ${theme.spacings.unit(24)} ${theme.spacings.unit(24)}`,
  width: `calc(100% - ${theme.spacings.unit(26 * 2)})`,
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  gap: `${theme.spacings.unit(8)}`,
  justifyContent: 'center',
  boxSizing: 'content-box',
  [theme.breakpointsMediaQuery.down('sm')]: {
    padding: `${theme.spacings.unit(2)} ${theme.spacings.unit(9)}`,
    paddingBottom: theme.spacings.unit(14),
    ...(!elementsNr.numberOfElementsXS && {
      flexWrap: 'nowrap',
      flexDirection: 'column',
    }),
    width: 'auto',
    '& > div': {
      flexBasis: elementsNr.numberOfElementsXS
        ? `calc(100% / ${elementsNr.numberOfElementsXS} - ${theme.spacings.unit(
          8
        )})`
        : 'auto',
      maxWidth: elementsNr.numberOfElementsXS
        ? `calc(100% / ${elementsNr.numberOfElementsXS} - ${theme.spacings.unit(
          8
        )})`
        : '100%',
    },
  },
  [theme.breakpointsMediaQuery.between('sm', 'md')]: {
    padding: `${theme.spacings.unit(2)} ${theme.spacings.unit(18)}`,
    paddingBottom: theme.spacings.unit(14),
    ...(!elementsNr.numberOfElementsXS && {
      flexWrap: 'nowrap',
      flexDirection: 'column',
    }),
    justifyContent: 'space-evenly',
    '& > div': {
      flexBasis: elementsNr.numberOfElementsSM
        ? `calc(100% / ${elementsNr.numberOfElementsSM} - ${theme.spacings.unit(
          8
        )})`
        : 'auto',
      maxWidth: elementsNr.numberOfElementsSM
        ? `calc(100% / ${elementsNr.numberOfElementsSM} - ${theme.spacings.unit(
          8
        )})`
        : '100%',
    },
  },
  [theme.breakpointsMediaQuery.between('md', 'lg')]: {
    paddingLeft: theme.spacings.unit(26),
    paddingRight: theme.spacings.unit(26),
    flexDirection: 'row',
    flexWrap: 'wrap',
    '& > div': {
      flexBasis: elementsNr.numberOfElementsMD
        ? `calc(100% / ${elementsNr.numberOfElementsMD} - ${theme.spacings.unit(
          8
        )})`
        : `calc(33.3% - ${theme.spacings.unit(8)})`,
      maxWidth: elementsNr.numberOfElementsMD
        ? `calc(100% / ${elementsNr.numberOfElementsMD} - ${theme.spacings.unit(
          8
        )})`
        : `calc(33.3% - ${theme.spacings.unit(8)})`,
    },
  },
  [theme.breakpointsMediaQuery.between('lg', 'xl')]: {
    '& > div': {
      flexBasis: elementsNr.numberOfElementsLG
        ? `calc(100% / ${elementsNr.numberOfElementsLG} - ${theme.spacings.unit(
          8
        )})`
        : `calc(25% - ${theme.spacings.unit(8)})`,
      maxWidth: elementsNr.numberOfElementsLG
        ? `calc(100% / ${elementsNr.numberOfElementsLG} - ${theme.spacings.unit(
          8
        )})`
        : `calc(25% - ${theme.spacings.unit(8)})`,
    },
  },
  [theme.breakpointsMediaQuery.up('xl')]: {
    paddingLeft: theme.spacings.unit(20 / 4),
    paddingRight: theme.spacings.unit(20 / 4),
    width: `calc(100% - ${theme.spacings.unit((20 / 4) * 2)})`,
    '& > div': {
      flexBasis: elementsNr.numberOfElementsXL
        ? `calc(100% / ${elementsNr.numberOfElementsXL} - ${theme.spacings.unit(
          8
        )})`
        : `calc(25% - ${theme.spacings.unit(8)})`,
      maxWidth: elementsNr.numberOfElementsXL
        ? `calc(100% / ${elementsNr.numberOfElementsXL} - ${theme.spacings.unit(
          8
        )})`
        : `calc(25% - ${theme.spacings.unit(8)})`,
    },
  },
}));
