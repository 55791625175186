import {
  CardWrapper,
  CategoryWrapper,
  ImageWrapper,
  InfoWrapper,
  NameWrapper,
} from './cardProduct.style';
import { hexToRgb, rgbDataURL } from 'corporate-utils';
import { getStrapiMedia } from '../../../utils/media';
import Link from 'next/link';
import { DEFAULT_IMAGE } from '../../../models/domain/const';
import ImageWithFallback from '../../image-with-fallback/ImageWithFallback';

const CardProduct: React.FC<any> = ({ product, isPrerenderRequest }) => {
  return (
    <Link href={product?.link || '/'} passHref>
      <CardWrapper>
        <ImageWrapper>
          <ImageWithFallback
            layout={'fill'}
            objectFit="cover"
            placeholder="blur"
            blurDataURL={rgbDataURL(
              hexToRgb('ffffff')?.[0],
              hexToRgb('ffffff')?.[1],
              hexToRgb('ffffff')?.[2]
            )}
            src={getStrapiMedia(product?.images?.[0] ?? '')}
            alt={''}
            fallbackSrc={DEFAULT_IMAGE}
            isPrerenderRequest={isPrerenderRequest}
          />
        </ImageWrapper>
        <InfoWrapper>
          <CategoryWrapper>
            {product?.categoriesCalculatedInfo?.[0]?.[1]?.name || ''}
          </CategoryWrapper>
          <Link href={product?.link || '/'} passHref>
            <NameWrapper>{product?.nameFather}</NameWrapper>
          </Link>
        </InfoWrapper>
      </CardWrapper>
    </Link>
  );
};

export default CardProduct;
