import { styled } from '@mui/material';

export const OuterWrapper = styled('div')(({ theme, color }) => ({
  backgroundColor: color || `${theme.colors.secondaryBackground}`,
}));

export const InnerWrapper = styled('div')(({ theme }) => ({
  maxWidth: theme.spacings.unit(490),
  margin: '0 auto',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
}));

export const PresentationTop = styled('div')(({ theme }) => ({
  maxWidth: `calc(44% + ${theme.spacings.unit(24)})`,
  display: 'flex',
  gap: `${theme.spacings.unit(4)}`,
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  flex: '40%',
}));
